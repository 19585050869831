// src/utils/validationSchema.js
import * as Yup from 'yup';

/////////////// Registration form ////////////////////////
export const registrationInitialValues = {
    userName: '',
    mobileNo: '',
    email: '',
    password: '',
};
export const registrationSchema = Yup.object().shape({
    userName: Yup.string().required('User name is required'),
    mobileNo:
        Yup.string().required('Mobile number is required')
            .min(10, 'Mobile number must be 10 digits')
            .max(10, 'Mobile number must be 10 digits'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

/////////////// Login form ////////////////////////
export const loginInitialValues = {
    userName: '',
    mobileNo: '',
    email: '',
    password: '',
};
export const loginSchema = Yup.object().shape({
    mobileNo: Yup.string().required('Mobile number is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

/////////////// Group form ////////////////////////
export const groupInitialValues = {
    groupName: '',
    description: ''
};
export const groupSchema = Yup.object().shape({
    groupName: Yup.string().required('Group name is required'),
    description: Yup.string().required('Description is required'),
});

/////////////// Add group contact form ////////////////////////
export const contactInitialValues = {
    contacts: [{ userName: '', mobileNo: '' }],
};
export const contactSchema = Yup.object().shape({
    contacts: Yup.array().of(
        Yup.object().shape({
            userName: Yup.string().required('Contact Name is required'),
            mobileNo: Yup.string().matches(/^\d{10}$/, 'Invalid mobile number').required('Mobile Number is required'),
        })
    ),
});

/////////////// Add group contact form ////////////////////////
export const contactExcelInitialValues = {
    file: '',
};
const Excel_SUPPORTED_FORMATS = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

export const contactExcelSchema = Yup.object().shape({
    file: Yup.mixed()
        .required('Excel file is required')
        .test(
            'fileSize',
            'File too large. it\'s size should be less then 5MB.',
            value => {
                return value && value.size <= (1048576 * 5) // 5MB
            }
        )
        .test(
            'fileType',
            'Unsupported file format. Accepted only excel file',
            value => value && Excel_SUPPORTED_FORMATS.includes(value.type)
        ),
});


/////////////// Add qr send broadcast form ////////////////////////
export const qrBroadcastInitialValues = {
    title: '',
    description: ''
};
export const titleSchema = Yup.object().shape({
    title: Yup.string().required('Title name is required'),
    description: Yup.string().required('Description is required')
});

/////////////// Template form ////////////////////////
export const templateInitialValues = {
    templateName: '',
    category: '',
    language: '',
    header_type: '',
    header_text: '',
    header_image: '',
    header_video: '',
    header_document: '',
    body: '',
    footer: '',
    button_type:'',
    quickReplies: [''] // Dynamically added input fields

};

const TEMPALTE_SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const TEMPALTE_IMG_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const TEMPALTE_VDO_FORMATS = ["video/mp4"];
const TEMPALTE_DOC_FORMATS = ["application/pdf"];
export const templateSchema = Yup.object().shape({
    templateName: Yup.string().required('Template name is required'),
    category: Yup.string().required('Category is required'),
    language: Yup.string().required('Language is required'),

    header_type: Yup.string()
        .oneOf(['text', 'image', 'video', 'document', ''])
        .nullable(),
    header_text: Yup.string().when('header_type', {
        is: 'text',
        then: () => Yup.string()
            .max(60, 'Header text cannot be longer than 60 characters')
            .required('Header text is required'),
    }),

    header_image: Yup.mixed().when('header_type', {
        is: 'image',
        then: () => Yup.mixed()
            .required('Header Image is required')
            .test(
                "FILE_SIZE",
                "Uploaded file is too big. max file size upto 5MB.",
                value => !value || (value && value.size <= 5 * 1024 * 1024) // 5MB limit
            )
            .test(
                "FILE_FORMAT",
                "Selected file should be jpg, jpeg, png format.",
                value => value && TEMPALTE_IMG_FORMATS.includes(value.type)
            )
    }),
    header_video: Yup.mixed().when('header_type', {
        is: 'video',
        then: () => Yup.mixed()
            .required('Header Video is required')
            .test(
                "FILE_SIZE",
                "Uploaded file is too big. max file size upto 16MB.",
                value => !value || (value && value.size <= 16 * 1024 * 1024) // 5MB limit
            )
            .test(
                "FILE_FORMAT",
                "Selected file should be mp4 format.",
                value => value && TEMPALTE_VDO_FORMATS.includes(value.type)
            )
    }),
    header_document: Yup.mixed().when('header_type', {
        is: 'document',
        then: () => Yup.mixed()
            .required('Header Document is required')
            .test(
                "FILE_FORMAT",
                "Selected file should be a PDF.",
                value => value && TEMPALTE_DOC_FORMATS.includes(value.type)
            )
    }),
    body: Yup.string()
        .max(1024, 'Body text cannot be longer than 1024 characters')
        .required('Body is required'),

    quickReplies: Yup.array().when('button_type', {
        is: 'quick_reply', // If button_type is 'Quick Reply', quickReplies are required
        then: () => Yup.array()
            .of(Yup.string().required('This field is required').max(25,"Button text max limit 25 characters"))
      })

});