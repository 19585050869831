import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/img/growwuo_logo.png'
import abountImg from '../../assets/website/img/about.jpg'
import '../../assets/website/css/style2.css';
import '../../assets/website/css/bootstrap.min.css';

function NewHome() {
    const navigate = useNavigate();
    return (<>



        <nav id="mainNav" className="sidebar sidebar-default sidebar-fixed-top">
            <div className="container">
                <div className="sidebar-header">

                    <a className="sidebar-brand" onClick={(e) => navigate('/')}><img src={Logo} alt="Logo" />
                    </a>
                </div>


                <div className="collapse sidebar-collapse">
                    <ul className="side sidebar-side sidebar-rights">
                        <li>
                            <a className="page-scroll" href="#home">HOME</a>
                        </li>
                        <li>
                            <a className="page-scroll" href="#about">ABOUT</a>
                        </li>
                        <li>
                            <a className="page-scroll" href="#services">SERVICES</a>
                        </li>
                        <li>
                            <a class="page-scroll" href="#contact">CONTACTS</a>
                        </li>
                        <li>
                            <a className="btn-wt-bdr2" onClick={(e) => navigate('/login')}  >Sign-in</a>
                        </li>

                    </ul>
                </div>

            </div>
        </nav>

        <header id="home" className="header-home" style={{ "margin-left": "30px !important" }}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <div className="header-text">
                            {/* <h1>Growwup</h1> */}
                            {/* <p>Innovative Digital Solutions for Tomorrow’s Growth</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </header>
        {/* <section className="section-padding welcome">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Creative High <span>Quality</span> And <span>Clean</span> Theme</h2>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus</p>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        <section id="about" className="section-padding about">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title">
                                    <h2>ABOUT <span>CREATIV</span></h2>
                                    <p>Growwup is at the forefront of providing innovative digital marketing solutions tailored to meet the unique needs of businesses at every stage. </p>
                                    <p> Our platform integrates a suite of powerful tools designed to streamline your marketing efforts and drive tangible results. </p>
                                    <p> Whether you're a startup seeking to establish an online presence or an established business looking to scale, Growwup is your trusted partner in digital marketing success.</p>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 work">
                                        <h3>How We <span>Work ?</span></h3>
                                        <p>Understand Your Needs: We begin by analyzing your business goals and target audience.</p>
                                        <p>Customized Solutions: We create a tailored digital marketing plan using WhatsApp, chatbots, broadcasts, and more.</p>
                                        <p>Seamless Implementation: Our team manages the entire setup and execution of your marketing campaigns.</p>
                                        <p>Ongoing Support: We provide continuous optimization and support to maximize results.</p>
                                        <p>Result-Driven Focus: Every action is aimed at delivering measurable growth for your business.</p>

                                        <a className="btn-wt-bdr2" onClick={(e) => navigate('/sign-up')} >Get Started</a>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={abountImg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <section className="section-padding about-bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>PROVIDING <span>EXCELLENCE</span> IN <span>THINGS</span> WE DO</h2>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus</p>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus Nam elementum eleifend diam a vulputate. Curabitur egestas nisl lacus, vitae ullamcorper lacus placerat eget. Sed tium vehicula tristique. Quisque vel metus</p>
                                <a href="#" className="btn-wt-bdr2">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

        <section id="services" className="section-padding features">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="title">
                            <h2>OUR <span>SERVICES</span></h2>
                            <p>To revolutionize the digital marketing landscape by offering state-of-the-art tools and personalized strategies that empower businesses to achieve unprecedented growth.</p>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-md-6">
                                <div className="row">
                                    <div className="col-md-4"><i aria-hidden="true" className="fa fa-diamond"></i></div>
                                    <div className="col-md-8">
                                        <h4>Broadcast<br /> </h4>
                                        <p>Maximize your marketing efforts by pushing out targeted campaigns through high-response WhatsApp messages.</p>
                                        <p>Easily tag contacts and categorize them into specific groups for personalized, meaningful communication.</p>
                                        <p>Tailor your messages to speak directly to your audience, increasing engagement and driving conversions.</p>
                                        <p>Use WhatsApp to stay connected with your customers, delivering timely offers and relevant updates.
                                            Create lasting relationships that go beyond one-time sales by regularly interacting with your audience.</p>
                                        <p>Build trust and loyalty by offering consistent, personalized customer experiences on WhatsApp.</p>
                                        <p>Segment your contacts to send more relevant and impactful messages that resonate with their needs.</p>
                                        <p>Automate your marketing, sales, and support messages to save time while maintaining a personal touch.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6">
                                <div className="row">
                                    <div className="col-md-4"><i className="fa fa-mobile" aria-hidden="true"></i></div>
                                    <div className="col-md-8">
                                        <h4>Chatbots<br /> </h4>
                                        <p>Build smart, no-code chatbots to instantly respond to common customer inquiries.</p>
                                        <p>  Effortlessly manage large-scale communications with automated responses.</p>
                                        <p>Provide personalized customer interactions that feel human but are powered by bots.</p>
                                        <p>Enhance customer satisfaction by offering real-time support, 24/7.</p>
                                        <p>Automate routine tasks, freeing up your team to focus on more complex issues.</p>
                                        <p>Seamlessly integrate chatbots into your WhatsApp communication strategy.</p>
                                        <p>Increase sales by engaging customers directly through automated chat.</p>
                                        <p>Convert more leads and drive revenue with customized conversations.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <section id="contact" class="section-padding team-box">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="title">
                            <h2>Content </h2>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="contact-bg">
                                        <form class="contact-form">

                                            <div class="form-group">
                                                <input class="form-control" id="name" name="name" placeholder="Your name" type="text" value="" />
                                            </div>

                                            <div class="form-group">
                                                <input class="form-control" id="email" name="email" type="email" placeholder="Your email" value="" />
                                            </div>

                                            <div class="form-group">
                                                <textarea class="form-control" id="message" name="message" rows="2" placeholder="Message" required></textarea>
                                            </div>

                                            <div class="row">
                                                <div class="col-xs-12">
                                                    <div class="text-center">
                                                        <button type="submit" class="btn btn-custom" >Submit</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        <section id="contact" className="section-padding newsletter" style={{ "padding": "25px" }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="title-address">
                            <h2 style={{ "text-transform": "none" }}>Email :<span>admin@growwups.in </span></h2>
                            <h2>Content :<span>+9189596971763 </span></h2>

                        </div>
                        {/* <div className="row">
                            <div className="col-sm-10 col-sm-offset-1">
                                <form>
                                    <input type="email" placeholder="Enter e-mail address" id="exampleInputEmail1" className="form-control input-subscribe" />
                                    <button className="btn btn-white-fill" type="submit">Subscribe </button>
                                </form>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>

        <footer className="section-padding footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="footer-padding">
                            <div className="row">
                                <div className="col-md-12 text-center">

                                    <div className="row">
                                        <div className="col-md-6 copyright">
                                            <p> <span>&copy;</span> 2024 Growwup Digital Solutions  |  All Rights Reserved</p>
                                        </div>
                                        <div className="col-md-6 copyright">
                                            <p>Privacy Policy <a className='class="btn-wt-bdr2"' onClick={(e) => navigate('/privacy-policy')}  >click here</a> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


    </>)
}

export default NewHome;