import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from '../components/Website/Home'

import Signup from '../components/Auth/Signup'
import Login from '../components/Auth/Login';
import Dashboard from '../components/UserPanel/Dashboard';
import PrivateRoute from './PrivateRoute';
import PageNotFound from '../components/Website/PageNotFound';
import GroupList from '../components/UserPanel/Group/GroupList';
import GroupForm from '../components/UserPanel/Group/GroupForm';
import GroupAddContact from '../components/UserPanel/Group/GroupAddContact';
import GroupContactList from '../components/UserPanel/Group/GroupContactList';
import BroadcastList from '../components/UserPanel/Broadcast/BroadcastList';
import CreateBroadcast from '../components/UserPanel/Broadcast/CreateBroadcast';
import NewHome from '../components/Website/NewHome';
import BroadcastDetail from '../components/UserPanel/Broadcast/BroadcastDetail';
import TemplateList from '../components/UserPanel/Template/TemplateList';
import TemplateForm from '../components/UserPanel/Template/TemplateForm';
import TemplateFormEdit from '../components/UserPanel/Template/TemplateFormEdit';
import PrivacyPolicy from '../components/Website/PrivacyPolicy';
import BroadcastTemplateForm from '../components/UserPanel/BroadcastTemplate/BroadcastTemplateForm';

function Routers() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<NewHome />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/sign-up" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route
                    path="/dashboard"
                    element={
                        <PrivateRoute>
                            <Dashboard />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/group"
                    element={
                        <PrivateRoute>
                            <GroupList />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/add-group"
                    element={
                        <PrivateRoute>
                            <GroupForm />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/group/add-contact/:groupId"
                    element={
                        <PrivateRoute>
                            <GroupAddContact />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/group/contact-list/:groupId"
                    element={
                        <PrivateRoute>
                            <GroupContactList />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/broadcast"
                    element={
                        <PrivateRoute>
                            <BroadcastList />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/create-broadcast"
                    element={
                        <PrivateRoute>
                            <CreateBroadcast />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/broadcast-detail/:broadcastId"
                    element={
                        <PrivateRoute>
                            <BroadcastDetail />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/template"
                    element={
                        <PrivateRoute>
                            <TemplateList />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/add-template"
                    element={
                        <PrivateRoute>
                            <TemplateForm />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/add-broadcast-template"
                    element={
                        <PrivateRoute>
                            <BroadcastTemplateForm />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/edit-template/:templateId"
                    element={
                        <PrivateRoute>
                            <TemplateFormEdit />
                        </PrivateRoute>
                    }
                />

                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Routers;