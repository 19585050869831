// src/api/authApi.js
import axiosInstance from './axiosInstance';

/* add group form
let postData = {
  let postData = {
                name: formData.groupName,
                description: formData.description,
            }
}*/
export const addTemplate = async (postData) => {
  try {
    const response = await axiosInstance.post('/create-template', postData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* add group form
let postData = {
  let postData = {
                name: formData.groupName,
                description: formData.description,
            }
}*/
export const addBroadcastTemplate = async (postData) => {
  try {
    const response = await axiosInstance.post('/create-whatsapp-template', postData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTemplate = async (postData,templateId) => {
  try {
    const response = await axiosInstance.put(`/update-template?id=${templateId}`, postData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTemplateList = async (postData) => {
  try {
    const response = await axiosInstance.get('/getall-template',{
      params: postData
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* add group form
let postData = {
  let postData = {
                name: formData.groupName,
                description: formData.description,
            }
}*/
export const deleteTemplate = async (itemId) => {
  try {
    const response = await axiosInstance.delete(`/delete-template?id=${itemId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTemplateInfoApi = async (itemId) => {
  try {
    const response = await axiosInstance.get(`/get-template-by-id?id=${itemId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// count group api

export const getTemplateCount = async () => {
  try {
    const response = await axiosInstance.get('/template-count');
    return response.data;
  } catch (error) {
    throw error;
  }
};
// /* add group contact form
// let postData = {
//   let postData = {
//                 groupId: groupId,
//                 userId: userId,
//                 name: formData.name,
//                 mobileNo: formData.mobileNo,
//                 mobileCode: '+91',
//                 type: type,
//             }
// }*/
// export const groupAddContact = async (postData) => {
//   try {
//     const response = await axiosInstance.post('/create/contact', postData);
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

// // get group contact list
// export const getGroupContactList = async (groupId) => {
//   try {
//     const response = await axiosInstance.get(`/get/groupbyid?id=${groupId}`);
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

// /* add group form
// let postData = {
//   let postData = {
//                 name: formData.groupName,
//                 description: formData.description,
//             }
// }*/
// export const deleteGroupContact = async (itemId) => {
//   try {
//     const response = await axiosInstance.delete(`/delete/group?id=${itemId}`);
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

// // count group api

// export const getGroupCount = async () => {
//   try {
//     const response = await axiosInstance.get('/group-count');
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

