import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Icon from 'react-bootstrap-icons';
import Header from '../../Website/Header';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';
import { templateInitialValues, templateSchema } from '../../../utils/validationSchema';
import { addBroadcastTemplate, addTemplate } from '../../../api/templateApi';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../../context/AuthContext';
import Loader from '../../common/Loader';
import languages from "./languages.json";


function BroadcastTemplateForm() {
    const [loading, setLoading] = useState(false);
    const { userData } = useAuthContext();
    const [headerPreview, setHeaderPreview] = useState('')
    const [bodyPreview, setBodyPreview] = useState('');
    const [footerPreview, setFooterPreview] = useState('')
    const [buttonTypePreview, setButtonTypePreview] = useState('')
    const [quickReplyPreview, setQuickReplyPreview] = useState([''])
    const [fileImgPreview, setImgFilePreview] = useState(null);
    const [fileVdoPreview, setVdoFilePreview] = useState(null);
    const [documentPreview, setDocumentPreview] = useState(null);
    const headerImageRef = useRef(null)
    const headerVideoRef = useRef(null)
    const headerDocumentRef = useRef(null)
    const fileInputRef = useRef(null); // Reference for file input

    const navigate = useNavigate();
    const handleSubmit = async (htmlFormData) => {

        console.log('htmlFormData', htmlFormData);
        // return

        // setLoading(true);

        try {
            const formData = new FormData();
            formData.append('name', htmlFormData.templateName);
            formData.append('templateType', 'PreApproved');
            formData.append('category', htmlFormData.category);
            formData.append('language', htmlFormData.language);
            formData.append('headerType', htmlFormData.header_type);
            formData.append('headerText', htmlFormData.header_text);
            if (htmlFormData.header_type === "image") {
                formData.append('headerFile', htmlFormData.header_image);

            } else if (htmlFormData.header_type === "video") {
                formData.append('headerFile', htmlFormData.header_video);

            } if (htmlFormData.header_type === "document") {
                formData.append('headerFile', htmlFormData.header_document);
            }
            formData.append('createdby', userData._id);

            let responceData = await addBroadcastTemplate(formData);
            toast.success('Template created successful!');
            // navigate('/template')

        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Create template failed';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (event, setFieldValue, fileId) => {
        const file = event.currentTarget.files[0];
        setFieldValue(fileId, file);

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (fileId === 'header_video') {
                    setVdoFilePreview(reader.result)
                } else if (fileId === 'header_document') {
                    setDocumentPreview(reader.result)
                } else {
                    setImgFilePreview(reader.result);
                }
            };
            reader.readAsDataURL(file);
        } else {
            if (fileId === 'header_video') {
                setVdoFilePreview(null)
            } else if (fileId === 'header_document') {
                setDocumentPreview(null)
            } else {
                setImgFilePreview(null);
            }
        }
    };

    const removeFile = (setFieldValue) => {
        setFieldValue("file", null);
        setImgFilePreview(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value
        }
    };


    return (<>
        <Header />
        {loading && <Loader />}
        <main className="main mainheight">
            <div className="container">

                <h5 className="title">Add Broadcast Template</h5>
                <div className="col mb-2" style={{ float: 'right' }}>
                    <button onClick={(e) => navigate('/template')} className="btn btn-theme btn-md" >Back</button>
                </div>
                <div className="container">
                    <div className="row mb-4 py-2">
                        <div className="col text-center">
                            <h4>Create template for send <span className="text-gradient">Broadcast</span> to users</h4>
                        </div>
                    </div>
                    <div className="row">
                        {/* Template Form section start */}
                        <div className="col-12 col-md-8">
                            <Formik
                                initialValues={templateInitialValues}
                                validationSchema={templateSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting, setFieldValue, values }) => (
                                    // console.log(errors, touched, values)
                                    <Form>
                                        <div className="col-12 ">
                                            <div className="col-12 ">
                                                <div className="row mb-4">
                                                    <div className="col-12 col-md-6 col-lg-5 ">
                                                        <div className="form-template mb-3 position-relative check-valid">
                                                            <div className="input-template input-template-lg">
                                                                <div className="form-floating">
                                                                    <Field type="text" name="templateName" className="form-control border-start-0" />
                                                                    <label>Template Name <span className='star'>*</span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="templateName" component="div" className="error" />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-3 ">
                                                        <div className="form-template mb-3 position-relative check-valid">
                                                            <div className="input-template input-template-lg">
                                                                <div className="form-floating">
                                                                    <Field as="select" name="category" className="form-control">
                                                                        <option value='' >Select</option>
                                                                        <option value="MARKETING">Marketing</option>
                                                                        <option value='UTILITY'>Utility</option>

                                                                    </Field>
                                                                    <label>Category <span className='star'>*</span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="category" component="div" className="error" />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 ">
                                                        <div className="form-template mb-3 position-relative check-valid">
                                                            <div className="input-template input-template-lg">
                                                                <div className="form-floating">
                                                                    <Field as="select" name="language" className="form-control">
                                                                        <option value='' >Select</option>
                                                                        {languages.map((languageObj, index) => {
                                                                            return <option key={`lang_${index}`} value={languageObj.Code}>{languageObj.Language}</option>
                                                                        })}

                                                                    </Field>
                                                                    <label>Language <span className='star'>*</span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="language" component="div" className="error" />
                                                    </div>
                                                </div>
                                            </div>


                                            {/* Header section  start */}
                                            <div className="col-12 ">
                                                <div className="mb-2">
                                                    <div className="form-template mb-3 position-relative check-valid">
                                                        <div className="input-template input-template-lg">
                                                            <div className="form-floating">
                                                                <Field as="select" name="header_type"
                                                                    onChange={(e) => {
                                                                        setFieldValue("header_type", e.target.value);
                                                                        // Clear previews when changing header type
                                                                        setImgFilePreview(null);
                                                                        setVdoFilePreview(null);
                                                                        setDocumentPreview(null);
                                                                        setHeaderPreview('')
                                                                        setFieldValue('header_text', '')
                                                                    }}
                                                                    className="form-control">
                                                                    <option value='' >None</option>
                                                                    <option value='text' >Text</option>
                                                                    <option value='image' >Image</option>
                                                                    <option value='video' >Video</option>
                                                                    <option value='document' >Document</option>
                                                                </Field>
                                                                <label>Header (Optional)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="header_type" component="div" className="error" />
                                                </div>
                                            </div>
                                            {values.header_type !== '' && (
                                                <div className="col-12 ">
                                                    {values.header_type === 'text' && (
                                                        <div className="mb-2">
                                                            <div className="form-template mb-3 position-relative check-valid">
                                                                <div className="input-template input-template-lg">
                                                                    <div className="form-floating">
                                                                        <Field type="text" name="header_text"
                                                                            onChange={(e) => {
                                                                                setFieldValue('header_text', e.target.value);
                                                                                setHeaderPreview(e.target.value);
                                                                            }}
                                                                            className="form-control border-start-0" />
                                                                        <label>Header Text <span className='star'>*</span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name="header_text" component="div" className="error" />
                                                        </div>
                                                    )}

                                                    {values.header_type === 'image' && (
                                                        <div className="mb-2">
                                                            <div className="form-template mb-3 position-relative check-valid">
                                                                <div className="input-template input-template-lg">
                                                                    <div className="form-floating">
                                                                        <input
                                                                            id="header_image"
                                                                            name="header_image"
                                                                            type="file"
                                                                            className="form-control"
                                                                            onChange={(event) => handleFileChange(event, setFieldValue, 'header_image')}
                                                                            ref={headerImageRef} // Assign reference to file input
                                                                            accept=".jpg, .jpeg, .png"
                                                                        />
                                                                        <label>Header Image <span className='star'>*</span> (.jpeg, .jpg, .png)</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name="header_image" component="div" className="error" />
                                                        </div>
                                                    )}

                                                    {values.header_type === 'video' && (
                                                        <div className="mb-2">
                                                            <div className="form-template mb-3 position-relative check-valid">
                                                                <div className="input-template input-template-lg">
                                                                    <div className="form-floating">
                                                                        <input
                                                                            id="header_video"
                                                                            name="header_video"
                                                                            type="file"
                                                                            className="form-control"
                                                                            onChange={(event) => handleFileChange(event, setFieldValue, 'header_video')}
                                                                            ref={headerVideoRef} // Assign reference to file input
                                                                            accept=".mp4"
                                                                        />
                                                                        <label>Header Video <span className='star'>*</span> (.mp4)</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name="header_video" component="div" className="error" />
                                                        </div>
                                                    )}


                                                    {values.header_type === 'document' && (
                                                        <div className="mb-2">
                                                            <div className="form-template mb-3 position-relative check-valid">
                                                                <div className="input-template input-template-lg">
                                                                    <div className="form-floating">
                                                                        <input
                                                                            id="header_document"
                                                                            name="header_document"
                                                                            type="file"
                                                                            className="form-control"
                                                                            onChange={(event) => handleFileChange(event, setFieldValue, 'header_document')}
                                                                            ref={headerDocumentRef} // Assign reference to file input
                                                                            accept=".pdf"
                                                                        />
                                                                        <label>Header Document  <span className='star'>*</span> (.pdf)</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name="header_document" component="div" className="error" />
                                                        </div>
                                                    )}



                                                </div>)}
                                            {/* Header section end */}

                                            {/* Body Section start */}
                                            <div className="col-12">
                                                <div className="mb-2">
                                                    <div className="form-template mb-3 position-relative check-valid">
                                                        <div className="input-template input-template-lg">
                                                            <div className="form-floating">
                                                                <Field as="textarea" name="body" className="form-control border-start-0 h-auto" rows="5"
                                                                    onChange={(e) => {
                                                                        setFieldValue('body', e.target.value);
                                                                        setBodyPreview(e.target.value);
                                                                    }}
                                                                />
                                                                <label>Body <span className='star'>*</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="body" component="div" className="error" />
                                                </div>
                                            </div>
                                            {/* Body section end */}

                                            {/* Footer Section start */}
                                            <div className="col-12 ">
                                                <div className="mb-2">
                                                    <div className="form-template mb-3 position-relative check-valid">
                                                        <div className="input-template input-template-lg">
                                                            <div className="form-floating">
                                                                <Field type="text" name="footer"
                                                                    onChange={(e) => {
                                                                        setFieldValue('footer', e.target.value);
                                                                        setFooterPreview(e.target.value);
                                                                    }}
                                                                    className="form-control border-start-0" />
                                                                <label>Footer (Optional)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="footer" component="div" className="error" />
                                                </div>
                                            </div>
                                            {/* Footer section end */}

                                            {/* Button section start */}
                                            <div className="col-12 ">
                                                <div className="mb-2">
                                                    <div className="form-template mb-3 position-relative check-valid">
                                                        <div className="input-template input-template-lg">
                                                            <div className="form-floating">
                                                                <Field as="select"
                                                                    onChange={(e) => {
                                                                        setFieldValue('button_type', e.target.value)
                                                                        setButtonTypePreview(e.target.value)
                                                                    }}
                                                                    name="button_type" className="form-control">
                                                                    <option value='' >None</option>
                                                                    <option value="call_to_action">Call to Action</option>
                                                                    <option value='quick_reply'>Quick Reply</option>

                                                                </Field>
                                                                <label>Button (Optional)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="button_type" component="div" className="error" />
                                                </div>
                                            </div>
                                            {values.button_type !== '' && (
                                                values.button_type === "quick_reply" && (
                                                    <FieldArray name="quickReplies">
                                                        {({ insert, remove, push }) => (
                                                            <div className="col-12 ">

                                                                {values.quickReplies && values.quickReplies.length > 0
                                                                    ? values.quickReplies.map((reply, index) => (
                                                                        <div className="row mb-4" key={index}>
                                                                            <div className="col-12 col-md-8">
                                                                                <div className="mb-2">
                                                                                    <div className="form-template mb-3 position-relative check-valid">
                                                                                        <div className="input-template input-template-lg">
                                                                                            <div className="form-floating">
                                                                                                <Field type="text" name={`quickReplies.${index}`}
                                                                                                    onChange={(e) => {
                                                                                                        // Update the field value
                                                                                                        setFieldValue(`quickReplies.${index}`, e.target.value);

                                                                                                        // Update the quickReplyPreview array
                                                                                                        const newPreview = [...values.quickReplies];
                                                                                                        newPreview[index] = e.target.value;
                                                                                                        setQuickReplyPreview(newPreview);
                                                                                                    }}
                                                                                                    className="form-control border-start-0"
                                                                                                    maxlength="25"
                                                                                                />
                                                                                                <label>Button{index + 1}<span className='star'>*</span></label>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    <ErrorMessage name={`quickReplies.${index}`} component="div" className="error" />
                                                                                </div>
                                                                                {values.quickReplies.length - 1 === index && index < 2 && (
                                                                                    <button className="btn btn-sm btn-info" type="button"
                                                                                        onClick={() => {
                                                                                            push('')
                                                                                            setQuickReplyPreview([...quickReplyPreview, ''])
                                                                                        }}>Add Button +{2 - index}</button>
                                                                                )}

                                                                            </div>
                                                                            {values.quickReplies.length > 1 ? (
                                                                                <div className="col-12 col-md-4 ">
                                                                                    {`${reply.length}/25`}<br />
                                                                                    <button className="btn btn-sm btn-danger " type="button"
                                                                                        onClick={() => {
                                                                                            remove(index);
                                                                                            setQuickReplyPreview(preValue => {
                                                                                                let newData = preValue.filter((val, loopIndex) => loopIndex !== index)
                                                                                                return newData;
                                                                                            })
                                                                                        }} >X</button>
                                                                                </div>
                                                                            ) : (<div className="col-12 col-md-4 ">
                                                                                {`${reply.length}/25`}
                                                                            </div>)}


                                                                        </div>
                                                                    ))
                                                                    : null}


                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                )

                                            )}
                                            {/* Button section end */}

                                            <div style={{ display: 'flex' }}>
                                                <button style={{ height: '36px', marginRight: '30px' }} className="btn btn-theme" type="submit" disabled={isSubmitting}>Save</button>
                                            </div>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                        {/* Template Form section end */}

                        {/* Template Preview section start */}
                        <div className="col-12 col-md-4">
                            <section className="msger">
                                <header className="msger-header">
                                    <div className="msger-header-title">
                                        <i className="fas fa-comment-alt"></i> Chat Preview
                                    </div>
                                    <div className="msger-header-options">
                                        <span><i className="fas fa-cog"></i></span>
                                    </div>
                                </header>

                                <main className="msger-chat">
                                    <div className="msg left-msg">
                                        <div

                                        ><Icon.PersonBoundingBox className="msg-img" /></div>

                                        <div className="msg-bubble">
                                            <div className="msg-info">
                                                <div className="msg-info-name">BOT</div>
                                                {/* <div className="msg-info-time">12:45</div> */}
                                            </div>

                                            <div className="msg-text" style={{ whiteSpace: 'pre-wrap' }}>
                                                {fileImgPreview && (
                                                    <>
                                                        <img src={fileImgPreview} alt="Selected File Preview" style={{ width: '200px', height: '200px', marginBottom: '10px' }} />
                                                        <br />
                                                    </>
                                                )}

                                                {fileVdoPreview && (
                                                    <>
                                                        <video controls style={{ width: '200px', height: '200px', marginBottom: '10px' }}>
                                                            <source src={fileVdoPreview} type="video/mp4" />

                                                        </video>
                                                        <br />
                                                    </>
                                                )}

                                                {documentPreview && (
                                                    <>
                                                        <iframe
                                                            src={documentPreview}
                                                            style={{ width: '200px', height: '200px' }}
                                                            title="Document Preview"
                                                        />
                                                        <br />
                                                    </>
                                                )}

                                                {headerPreview && <><b>{headerPreview}</b><br /><br /></>}
                                                {bodyPreview && <>{bodyPreview}<br /><br /></>}
                                                {footerPreview && <>{footerPreview}<br /><br /></>}
                                                {buttonTypePreview === "quick_reply" && quickReplyPreview.length > 0 && (
                                                    quickReplyPreview.map((button, index) => {
                                                        return (
                                                            <div key={index} >
                                                                <button type="button" className="msger-send-btn mb-1" >{!button && `Button${index + 1}`}  {button}</button>
                                                                <br />
                                                            </div>
                                                        )
                                                    })
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </main>

                                <form className="msger-inputarea">
                                    <input type="text" className="msger-input" placeholder="Enter your message..." disabled />
                                    <button type="submit" className="msger-send-btn" disabled>Send</button>
                                </form>
                            </section>
                        </div>
                        {/* Template Preview section end */}
                    </div>
                </div>
                <div className='row'>
                    <div className=' className="col-6 col-md-6"'>

                    </div>
                    <div className='className="col-6 col-md-6"'>

                    </div>
                </div>

            </div>
        </main >
        <Footer />

    </>)
}

export default BroadcastTemplateForm;