import React, { useEffect } from 'react';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import maxartkiller from '../../assets/img/maxartkiller.png'
import topImg from '../../assets/img/bg-14.jpg'
import Logo from '../../assets/img/growwuo_logo.png'
// import '../../assets/website/css/style2.css';
// import '../../assets/website/css/bootstrap.min.css';


function PrivacyPolicy() {
    const navigate = useNavigate();
    useEffect(() => {
        // Dynamically load the CSS when the component is mounted (when About route is active)
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://growwups.in/mahiSport/assets/css/style2.css';  // Replace with actual third-party CSS URL
        document.head.appendChild(link);

        // Cleanup: Remove the CSS when the component is unmounted (when About route is inactive)
        return () => {
            document.head.removeChild(link);
        };
    }, []);
    return (<>



        <nav id="mainNav" className="sidebar sidebar-default sidebar-fixed-top">
            <div className="container">
                <div className="sidebar-header">

                    <a className="sidebar-brand" href="#"><img src={Logo} alt="Logo" />
                    </a>
                </div>


                <div className="collapse sidebar-collapse">
                    <ul className="side sidebar-side sidebar-rights">
                        <li>
                            <a className="page-scroll" onClick={(e) => navigate('/')}  >HOME</a>
                        </li>


                    </ul>
                </div>

            </div>
        </nav>

        <section id="about" className="section-padding about">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title" style={{ "text-align": "justify" }}>
                                    <h2>Privacy <span>Policy</span></h2>
                                    <h4>Privacy Policy for Growwup</h4>
                                    <p>At Growwup, we prioritize the privacy of our users. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website and services.</p>
                                    <h4>1. Information We Collect</h4>
                                    <p>Personal Information: When you interact with our platform, we may collect personal information such as your name, email address, contact number, and business details.</p>
                                    <p>Usage Data: We collect data on how you use our services, including IP addresses, browser type, and interactions with the platform.</p>
                                    <p>Communication Data: Information related to the messages, chats, and communication via services like WhatsApp broadcasts, chatbots, and other bulk messaging services.</p>
                                    <h4>2. How We Use Your Information</h4>
                                    <p>Service Delivery: To provide and maintain our services, including sending bulk messages, managing chatbots, and other marketing services.</p>
                                    <p>Improvement: To enhance our platform’s performance, analyze trends, and develop new features to improve your experience.</p>
                                    <p>Communication: We use your contact information to respond to your inquiries, send updates, or provide marketing materials.</p>
                                    <h4>3. Sharing of Information We do not sell or rent your personal information to third parties. We may share your information with:</h4>
                                    <p>Service Providers: Third-party companies that assist us in delivering our services.</p>
                                    <p>Legal Compliance: If required by law, we may disclose information in response to a legal process.</p>
                                    <h4>4. Data Security We take the security of your data seriously. </h4>
                                    <p> We use industry-standard security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p>
                                    <h4>5. Cookies and Tracking </h4>
                                    <p> Cookies and Tracking Growwup may use cookies and similar tracking technologies to track activity on our platform and enhance your experience.</p>
                                    <h4>6. Your Rights</h4>
                                    <p>Access: You have the right to access the personal data we hold about you.</p>
                                    <p>Correction: If any of your information is incorrect or outdated, you can request corrections.</p>
                                    <p>Deletion: You can request that we delete your data, subject to legal obligations.</p>
                                    <p>7. Changes to This Policy We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the revised policy will take effect immediately.</p>
                                </div>

                                <p>Contact Us If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
                                <h3>Email: admin@growwups.in</h3>
                                <h3>Phone: +9189596971763</h3>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <footer className="section-padding footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="footer-padding">
                            <div className="row">
                                <div className="col-md-12 text-center">

                                    <div className="row">
                                        <div className="col-md-6 copyright">
                                            <p>2024 <span>&copy;</span> Growwups</p>
                                        </div>
                                        <div className="col-md-6 copyright">
                                            <p>Privacy Policy <a className='class="btn-wt-bdr2"' onClick={(e) => navigate('/privacy-policy')}  >click here</a> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </>)
}
export default PrivacyPolicy;